import React, { Component } from "react"
import { Highlight } from "react-instantsearch-dom"
import { Link } from "gatsby"
import { formatDate } from "src/helpers"
import { config } from "data"

class SearchHit extends Component {
  constructor(props) {
    super(props)
  }

  getImageSource(hit) {
    const { siteUrl } = config
    const url = hit.thumbnail.publicURL
    return `${siteUrl}${url}`
  }
  render() {
    const { hit } = this.props
    return (
      <div className="card-box">
        <div className="card">
          <Link to={`/posts/${hit.fields.slug}`} className="card-title">
            {hit.title}
          </Link>
          <div className="card-block">
            <div className="card-image">
              <img className="card-thumbnail" src={this.getImageSource(hit)} />
            </div>
            <div className="card-body">
              <span className="card-text">
                <Highlight attribute="description" hit={hit} />
              </span>
              <div className="card-meta">
                <span>{formatDate(hit.fields.date)}</span>
                <span className="dot"></span>
                <span>{hit.fields.readingTime.text}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SearchHit
