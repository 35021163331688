import React, { Component } from "react"
import Helmet from "react-helmet"
import qs from "qs"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  RefinementList,
  ClearRefinements,
  SearchBox,
  PoweredBy,
  MenuSelect,
} from "react-instantsearch-dom"
import { config } from "data"
import Layout from "src/layouts"
import { StateResults } from "src/components/search"

const createURL = state => {
  return `?${qs.stringify(state)}`
}

const searchStateToUrl = ({ location }, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : ""

const urlToSearchState = ({ search }) => qs.parse(search.slice(1))

export class posts extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.location !== state.lastLocation) {
      return {
        searchState: urlToSearchState(props.location),
        lastLocation: props.location,
      }
    }
    return null
  }

  state = {
    searchState: urlToSearchState(this.props.location),
    lastLocation: this.props.location,
    isDrawerOpen: false,
  }

  handleClickOutsideDrawer(event) {
    if (this.drawerRef) {
      const el = this.drawerRef.current
      if (el) {
        if (!el.contains(event.target)) {
          this.closeDrawer()
        }
      }
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutsideDrawer)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideDrawer)
  }

  constructor(props) {
    super(props)
    this.onDrawerOpen = this.onDrawerOpen.bind(this)
    this.drawerRef = React.createRef()
    this.handleClickOutsideDrawer = this.handleClickOutsideDrawer.bind(this)
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState)

    this.debouncedSetState = setTimeout(() => {
      this.props.navigate(searchStateToUrl(this.props, searchState))
    }, 400)

    this.setState({ searchState })
  }

  onDrawerOpen() {
    this.setState({
      isDrawerOpen: true,
    })
  }

  closeDrawer() {
    this.setState({
      isDrawerOpen: false,
    })
  }

  getDrawerContainerClass() {
    const base = "posts-drawer-container"
    if (this.state.isDrawerOpen) {
      return `${base} posts-drawer-container--show`
    }
    return `${base} posts-drawer-container--hide`
  }

  getDrawerClass() {
    const base = "posts-drawer"
    if (this.state.isDrawerOpen) {
      return `${base} posts-drawer--show`
    }
    return `${base} posts-drawer--hide`
  }

  render() {
    const searchClient = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_SEARCH_KEY
    )

    return (
      <InstantSearch
        indexName={process.env.GATSBY_ALGOLIA_INDEX}
        searchClient={searchClient}
        searchState={this.state.searchState}
        onSearchStateChange={this.onSearchStateChange}
        createURL={createURL}
      >
        <Layout>
          <Helmet title={`posts - ${config.siteTitle}`} />
          <div className="posts">
            <div className="posts-sidebar visible-lg">
              <div className="posts-filters">
                <div className="posts-filters-heading">
                  <div className="posts-filters-title">Filters</div>
                  <div className="posts-filters-clear">
                    <ClearRefinements />
                  </div>
                </div>
                <div className="posts-filters-body">
                  <div className="posts-filter-title">Tags</div>
                  <div className="posts-filter-content">
                    <RefinementList attribute="tags" />
                  </div>
                </div>
              </div>
            </div>
            <div className="posts-content">
              <div className="posts-search">
                <div className="posts-search-box">
                  <SearchBox />
                </div>
                <div className="posts-search-mobile-filter hidden-lg">
                  <button
                    className="btn btn-sm"
                    onClick={this.onDrawerOpen.bind(this)}
                  >
                    Filters
                  </button>
                </div>
              </div>
              <div className="posts-menu">
                <div className="posts-menu-categories">
                  <MenuSelect
                    attribute="categories"
                    translations={{
                      seeAllOption: "All categories",
                    }}
                  />
                </div>
                <div className="posts-menu-powered-by">
                  <PoweredBy />
                </div>
              </div>
              <div className="posts-results">
                <StateResults />
              </div>
            </div>
          </div>
          <div className={this.getDrawerContainerClass()}>
            <div className={this.getDrawerClass()} ref={this.drawerRef}>
              <div className="modal-container">
                <div className="modal-header">
                  <button
                    onClick={this.closeDrawer.bind(this)}
                    className="posts-filters-close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="posts-filters">
                    <div className="posts-filters-heading">
                      <div className="posts-filters-title">Filters</div>
                      <div className="posts-filters-clear">
                        <ClearRefinements />
                      </div>
                    </div>
                    <div className="posts-filters-body">
                      <div className="posts-filter-title">Tags</div>
                      <div className="posts-filter-content">
                        <RefinementList attribute="tags" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </InstantSearch>
    )
  }
}

export default posts
