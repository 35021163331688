import React, { Component } from "react"
import { connectHits } from "react-instantsearch-dom"
import Hit from "./hit"

class Hits extends Component {
  constructor(props) {
    super(props)
  }

  renderHits() {
    const { hits } = this.props
    return hits.map(hit => <Hit key={hit.objectID} hit={hit} />)
  }

  render() {
    return <div>{this.renderHits()}</div>
  }
}

export default connectHits(Hits)
